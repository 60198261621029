
export interface ObjectProperty {
    name:string;
    value:any;
}

export function hasOwnProperty(obj:object, key:string) {
    return Object.prototype.hasOwnProperty.call(obj, key);
}

export class Util {

    static findIndex<T>(arr:T[], predicate:(item:T)=>boolean):number {
        for(let i=0; i<arr.length; i++) {
            if (predicate(arr[i])) return i;
        }
        return -1;
    }

    static find<T>(arr:T[], predicate:(item:T)=>boolean):T {
        let idx = Util.findIndex(arr, predicate);
        return idx < 0 ? undefined : arr[idx];
    }

    static ownProperties(obj:object):ObjectProperty[] {
        const props:ObjectProperty[] = [];
        for(const key in obj) {
            if (hasOwnProperty(obj, key)) {
                props.push({name:key, value:obj[key]});
            }
        }
        return props;
    }

}