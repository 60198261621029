import {hasOwnProperty} from './Util'

type ElxType = string | (new (props:any, children:ElxChildInstance[]) => Context);
type ElxChildInstance = HTMLElement | string | RootComponent | Control;
type ElxChild = ElxChildInstance | (() => ElxChildInstance);
type Callback = () => void;

interface Lookup<T> {
    [name:string]:T;
}

let EventRegex = /^on[A-Z]/
const PixelStyles:Lookup<boolean> = {
    left:true, right:true, top:true, bottom:true, width:true, height:true
};
// paddingLeft, etc
for(const thing of ['margin','padding','border']) {
    for(const dir of ['', 'Left','Right','Top','Bottom']) {
        PixelStyles[thing + dir] = true;
    }
}
//let PixelStyleRegex = /^(left|right|top|bottom|width|height|marginLeft)$/

function setStyle(el:HTMLElement, style:string, value:any) {
    //if (PixelStyleRegex.test(style) && typeof value === 'number') {
    if (PixelStyles[style] && typeof value === 'number') {
        el.style[style as any] = value + "px";
    } else {
        el.style[style as any] = value;
    }
}

const HTMLBooleanAttributes:any = {
    autocomplete:true, autofocus:true, autoplay:true, checked:true, contenteditable:true, 
    controls:true, default:true, disabled:true, hidden:true, readonly:true,
    required:true, selected:true, spellcheck:true, translate:true
}
function setAttribute(el:HTMLElement, attribute:string, value:any) {
    if (HTMLBooleanAttributes[attribute]) {
        if (value) {
            el.setAttribute(attribute, attribute);
        } else {
            el.removeAttribute(attribute);
        }
    } else {
        el.setAttribute(attribute, value)
    }
}

// Events always bubble up the chain
// Updates always bubble down the chain
// Functions that start with 'on' are events that bubble up

// Events are called in the context of 

export class Context {
    public static current:Context = new Context();
    private _updates:Callback[] = [];
    private _children:Context[] = [];   // TODO: Need some way to add/remove these?
    private _parent:Context;

    constructor() {
        this._parent = Context.current;
        if (this._parent) this._parent._children.push(this);
    }

    /*
     * Create a context contains a properties object which can be updated when the context is updated.
     * This Context.update() will re-evaluate those properties and invoke childContext.update() if any properties have changed.
     */
    public createEl(name:ElxType, props:any, ...children:ElxChild[]):ElxChildInstance {
        if (typeof name === 'string') {
            let el = document.createElement(name);
            this.applyProps(el, props);
            const addChild = (child:ElxChild) => {
                if (child === null || child === undefined) {
                    // Does nothing
                } else if (typeof(child) === "string") {
                    el.appendChild(document.createTextNode(child));
                } else if (child instanceof Node) {
                    el.appendChild(child);
                } else if (Array.isArray(child)) {
                    child.forEach(addChild);
                } else if (child instanceof RootComponent || child instanceof Control) {
                    // This is the only acceptable way to have dynamic content.
                    // Maybe... components are always dynamic? And can be re-rendered.
                    // You get whatever render() returns.  Can it be repeatedly called?
                    // Or perhaps the component's element be changed, and it gets monitored.
                    // How does a child component get unmounted?

                    // The component's ctor values need to be stashed away, and also the child elements
                    // 

                    // The context in which it was created controls: props and children
                    // Both need to be pushed into it
                    
                    addChild(child.doRender());
                } else if (typeof child === 'function') {
                    // Create a patch of content that is dynamically updated
                    const placeholderStartEl = el.appendChild(document.createComment(""));
                    const placeholderEndEl = el.appendChild(document.createComment(""));
                    const applyContent = (content:any) => {
                        let currentPlaceholder = placeholderStartEl.nextSibling;
                        const applyNext = (content:Node | String) => {
                            if (content instanceof Node || content instanceof Control || content instanceof RootComponent) {
                                const contentEl = elxRender(content);
                                if (currentPlaceholder === contentEl) {
                                    currentPlaceholder = currentPlaceholder.nextSibling;
                                } else {
                                    el.insertBefore(contentEl, currentPlaceholder);
                                }
                            } else if (typeof(content) === "string") {
                                if (currentPlaceholder.nodeType === Node.TEXT_NODE && currentPlaceholder.nodeValue === content) {
                                    currentPlaceholder = currentPlaceholder.nextSibling;
                                } else {
                                    el.insertBefore(document.createTextNode(content), currentPlaceholder);
                                }
                            } else if (content == null || typeof(content) == 'boolean') {
                                // nothing to insert
                            } else {
                                throw "unknown content type";
                            }
                        }
                        if (Array.isArray(content)) {
                            content.forEach(applyNext);
                        } else {
                            applyNext(content);
                        }
                        while(currentPlaceholder !== placeholderEndEl) {
                            const next = currentPlaceholder.nextSibling;
                            currentPlaceholder.parentNode.removeChild(currentPlaceholder);
                            currentPlaceholder = next;
                        }
                    }
                    applyContent(child());
                    this.addUpdateListener(el, () => applyContent(child()));
                } else {
                    throw "unknown child type";
                }
            }
            children.forEach(addChild);

            // Handy because the value of a select can't be set until it has its children
            if (props && hasOwnProperty(props, "initialValue")) {
                (el as any).value = props.initialValue;
            }
            return el;
        } else if (typeof name === 'function') {
            // TODO: For all the props
            //  - Anything starting with 'on...' becomes a callback (must be a function)
            //  - Any other function property becomes a dynamic property (flags the component as having dynamic props)
            // TODO: For all the children
            //  - Any that are dynamic are evaluated once now and saved for future re-evaluation
            if (children.some(child => typeof(child) === 'function')) {
                throw "Sorry, dynamic component children are not yet supported";
            }
            const cmp = (new name(props ?? {}, children as ElxChildInstance[]));

            if (!(cmp instanceof RootComponent || cmp instanceof Control)) {
                throw "Only elx components or controls are allowed";
            }

            // TODO:If the component has dynamic properties, these are stored in the current object.
            // When updated, the components are changed.
            // TODO: 
            //  - When the calling context is updated() all properties are re-evaluated and then pushed into the component
            return cmp;

        } else {
            throw "unsupported";
        }
    }

    public with<T>(createFn:() => T):T {
        let saved = Context.current;
        try {
            Context.current = this;
             return createFn();
        } finally {
            Context.current = saved;
        }
    }

    public addUpdateListener(el:HTMLElement, fn:Callback) {
        this._updates.push(fn);
    }

    public update() {
        this._updates.forEach(cb => cb());
    }

    private applyProps(el:HTMLElement, props:any) {
        for(let key in props) {
            let value = props[key];
            if (EventRegex.test(key)) {
                    if (typeof(value) !== 'function') throw new TypeError(`Event handler for "${key}" must be a function`);
                let eventName = key.substr(2).toLowerCase();
                // In the scope of the event handler, the context will be switched to the element's context
                // TODO: What happens with the 'this' parameter?
                // TODO: And does anything similar happen with Control properties?  Should it?
                el.addEventListener(eventName, (...args) => this.with(() => value.call(this, ...args)));
            } else if (key === 'style' && typeof(value) === 'object') {
                //if (typeof(value) !== 'object') throw new TypeError(`Styles must be an object`);
                this.applyStyles(el, value);
            } else {
                if (typeof(value) === 'function') {
                    this.addUpdateListener(el, () => setAttribute(el, key, value()));
                    setAttribute(el, key, value());
                } else {
                    setAttribute(el, key, value);
                }
            }
        }
    }

    private applyStyles(el:HTMLElement, styles:any) {
        for(let key in styles) {
            let value = styles[key];
            if (typeof value === 'function') {
                this.addUpdateListener(el, () => setStyle(el, key, value()));
                setStyle(el, key, value());
            } else {
                setStyle(el, key, value);
            }
        }
    }
}

/**
 * A control is renderable but does not define its own context.
 */
export abstract class Control<P extends {} = any> {
    private ctx:Context;

    constructor(public props:P, protected children:ElxChildInstance[] = []) {
        this.ctx = Context.current;
    }

    abstract render():ElxChildInstance;

    rendered:ElxChildInstance;
    doRender():ElxChildInstance {
        if (!this.rendered) {
            this.rendered = this.ctx.with(() => this.render());
        }
        return this.rendered;
    }

    /** Updates everything in the same context this control is in. */
    public updateContext() {
        this.ctx.update();
    }
}


/**
 * A root component defines its own update context
 */
export abstract class RootComponent<P extends {} = any> extends Context {
    constructor(public props:P, protected children:ElxChildInstance[] = []) {
        super();
    }

    // This should be called (automatically? When constructor params have changed)
    // Or just called when the parent is updated.
    // Or....??
    propsChanged() {
        this.update();
    }

    abstract render():ElxChildInstance;

    rendered:ElxChildInstance;
    doRender():ElxChildInstance {
        if (!this.rendered) {
            this.rendered = this.with(() => this.render());
        }
        return this.rendered;
    }
}

export function elx(name:'span', props:any, ...children:ElxChildInstance[]):HTMLSpanElement;
export function elx(name:'div', props:any, ...children:ElxChildInstance[]):HTMLDivElement;
export function elx(name:'input', props:any, ...children:ElxChildInstance[]):HTMLInputElement;
export function elx(name:string, props:any, ...children:ElxChildInstance[]):HTMLElement;
export function elx<T,P>(ctor:{ new (props:P): T }, props:P, ...children:ElxChildInstance[]):T;

export function elx(name:any, props:any, ...children:ElxChildInstance[]) {
    return Context.current.createEl(name, props, ...children);
}

export function elxRender(el:ElxChildInstance | Node):Node {
    if (el instanceof Node) {
        return el;
    } else if (el instanceof Control || el instanceof RootComponent) {
        return elxRender(el.doRender());
    } else if (typeof(el) === 'string') {
        return document.createTextNode(el);
    } else {
        const el = document.createElement("div");
        el.appendChild(document.createTextNode("???"));
        return el;
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [name:string]:any;
        }
    }
}