//import * as WebSocket from 'websocket';
//import * as SockJS from 'sockjs-client'

import { elx, Context, RootComponent, Control, elxRender } from '../../../Elx/src/elx'

import * as M from '../../shared/messages'

import './styles.css'

declare var page: { 
    appBase:string;
}

const globalCtx = new Context();
Context.current = globalCtx;


class LandingPage extends RootComponent {

    message:string;

    tableIdEl:HTMLInputElement;

    render() {
        return <div>
            <div class='page-header'>
                <div><img height="16" src={page.appBase + '/static/icon_logo.svg'}/> Phase for the Galaxy</div>
                <div></div>
            </div>
            <div style={{color:'#efece8', padding:"4px 8px"}}>
                <div>
                    Phase for the Galaxy is a replacement for the action cards in Race for the Galaxy.  You'll still need a physical copy
                    of the game, and must already know the phase icons.
                </div>
                <p>
                    To get started, create a table for you and your group to play on.
                </p>
                <div>
                    <button class='ui-button' onClick={this.onCreate}>Create Table</button>
                </div>
            </div>
        </div>
    }

    onCreate() {
        const code = new Array(5).fill(undefined).map(() => Math.random() * 22 | 0).map(n => "123456789ABCDEFGHJKMNP"[n]).join("");

        // Switch to the table UI for this table
        window.location.assign(page.appBase + "/table/" + code);
    }

    /*
    onCreateStandard() {
        this.message = "Creating standard...";
        this.update();

        const m:M.Client_CreateTable = { type:'createTable', variant:M.GameVariant.Standard };
        sock.send(JSON.stringify(m));

        // The arrival of a table status message will wipe out this control
    }

    onCreate2p() {
        this.message = "Creating advanced 2p...";
        this.update();

        const personId = window.localStorage?.getItem("player") ?? undefined
        const m:M.Client_CreateTable = { type:'createTable', variant:M.GameVariant.Advanced2P };
        sock.send(JSON.stringify(m));

        // The arrival of a table status message will wipe out this control
    }

    onJoin(e:MouseEvent) {
        this.message = "joining " + this.tableIdEl.value;
        this.update();

        const m:M.Client_JoinTable = { type:'joinTable', tableId:this.tableIdEl.value };
        sock.send(JSON.stringify(m));

        // Arrival of a response will wipe out this control
    }
    */
}

document.body.appendChild(elxRender(<LandingPage/>));
